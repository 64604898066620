import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {
    VisibilityIcon,
    VisibilityOffIcon
} from '@salesforce/retail-react-app/app/components/icons'
import {
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Select
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {colors, inputTypes} from '../../constants'
import RadioContainer from '../custom-rounded-radio/radio-container'
import {ChevronDownIcon, RoundDotIcon} from '../icons'

const FieldNewDesign = ({
    name,
    label,
    formLabel,
    type = inputTypes.text,
    options = [],
    rules = {},
    error,
    placeholder,
    inputProps,
    control,
    defaultValue,
    helpText,
    children,
    messages,
    required = true,
    hasStarInLabel = true,
    checkboxStyles = {},
    radioStyles = {},
    labelProps = {},
    inputGroupProps = {}
}) => {
    const [hidePassword, setHidePassword] = useState(true)

    const PasswordIcon = hidePassword ? VisibilityIcon : VisibilityOffIcon
    const inputType =
        type === inputTypes.password && hidePassword
            ? inputTypes.password
            : type === inputTypes.password
            ? inputTypes.text
            : type
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({field: {onChange, value, ref}}) => {
                const _inputProps =
                    typeof inputProps === 'function'
                        ? inputProps({value, onChange})
                        : inputProps
                return (
                    <FormControl
                        id={name}
                        isInvalid={error}
                        sx={{
                            '.chakra-select__icon-wrapper': {
                                right: '28px'
                            }
                        }}
                    >
                        {![
                            inputTypes.checkbox,
                            inputTypes.radio,
                            inputTypes.checkboxSquare
                        ].includes(type) &&
                            type !== inputTypes.hidden &&
                            (formLabel ||
                                (label && (
                                    <FormLabel
                                        fontSize="14px"
                                        fontWeight="300"
                                        marginBottom="8px"
                                        {...labelProps}
                                    >
                                        {required && hasStarInLabel && '*'}{' '}
                                        {label}
                                    </FormLabel>
                                )))}

                        <InputGroup {...inputGroupProps}>
                            {[
                                inputTypes.text,
                                inputTypes.password,
                                inputTypes.email,
                                inputTypes.phone,
                                inputTypes.tel,
                                inputTypes.number
                            ].includes(type) && (
                                <Input
                                    fontSize="16px"
                                    borderRadius="20px"
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    type={inputType}
                                    borderColor={colors.gunmetalGray}
                                    placeholder={placeholder}
                                    height="40px"
                                    {..._inputProps}
                                />
                            )}

                            {type === inputTypes.date && (
                                <Input
                                    fontSize="16px"
                                    borderRadius="20px"
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    type="text" // Initially text
                                    borderColor={colors.gunmetalGray}
                                    placeholder={placeholder}
                                    height="40px"
                                    onClick={(e) => {
                                        e.target.type = 'date'
                                        e.target.showPicker?.()
                                    }} // Convert to date & open picker
                                    onBlur={(e) => {
                                        if (!e.target.value) {
                                            e.target.type = 'text' // Revert if empty
                                        }
                                    }}
                                    _placeholder={{
                                        color: colors.black,
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto'
                                    }}
                                    {..._inputProps}
                                />
                            )}

                            {type === inputTypes.hidden && (
                                <input
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    type="hidden"
                                    {..._inputProps}
                                />
                            )}
                            {type === inputTypes.password && (
                                <InputRightElement height="100%">
                                    <IconButton
                                        variant="ghosted"
                                        aria-label="Show password"
                                        icon={
                                            <PasswordIcon
                                                color="gray.500"
                                                boxSize={6}
                                            />
                                        }
                                        onClick={() =>
                                            setHidePassword(!hidePassword)
                                        }
                                    />
                                </InputRightElement>
                            )}
                            {type === inputTypes.select && (
                                <Select
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    placeholder={placeholder}
                                    color={colors.black}
                                    fontSize={
                                        value
                                            ? '16px'
                                            : _inputProps?._placeholder
                                                  ?.fontSize
                                    }
                                    fontFamily="Roboto"
                                    borderRadius="20px"
                                    borderColor={colors.gunmetalGray}
                                    sx={{
                                        fontWeight: value
                                            ? '300'
                                            : _inputProps?._placeholder
                                                  ?.fontWeight,
                                        ..._inputProps
                                    }}
                                    icon={<ChevronDownIcon />}
                                >
                                    {options.map((opt) => (
                                        <option
                                            key={`${opt.label}-${opt.value}`}
                                            value={opt.value}
                                        >
                                            {opt.label}
                                        </option>
                                    ))}
                                </Select>
                            )}
                            {type === inputTypes.checkboxSquare && (
                                <Checkbox
                                    ref={ref}
                                    onChange={(e) => {
                                        onChange(e.target.checked)
                                    }}
                                    isChecked={value}
                                    {..._inputProps}
                                    sx={{
                                        '.chakra-checkbox__control': {
                                            border: '1px solid',
                                            borderColor: 'black',
                                            borderRadius: 'full', // Apply rounded corners
                                            backgroundColor:
                                                value && 'black !important',
                                            background:
                                                value && 'black !important',
                                            ...checkboxStyles
                                        }
                                    }}
                                    _placeholder={{
                                        color: colors.black,
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {formLabel || label}
                                </Checkbox>
                            )}
                            {type === inputTypes.checkbox && (
                                <Checkbox
                                    ref={ref}
                                    onChange={(e) => {
                                        onChange(e.target.checked)
                                    }}
                                    isChecked={value}
                                    {..._inputProps}
                                    iconColor="white" // Adjust the color of the dot as needed
                                    iconSize="14px" // Adjust the size of the dot as needed
                                    icon={<RoundDotIcon boxSize="10px" />}
                                    sx={{
                                        '.chakra-checkbox__control': {
                                            borderRadius: 'full', // Apply rounded corners
                                            _checkedAndChild: {
                                                transform: 'translateY(0.1em)'
                                            },
                                            border: '1px solid',
                                            borderColor: 'black !important',
                                            backgroundColor: 'white !important',
                                            background: 'white !important',
                                            outline: 'none',
                                            _hover: {
                                                backgroundColor:
                                                    'white !important'
                                            },
                                            ...checkboxStyles
                                        }
                                    }}
                                    _placeholder={{
                                        color: colors.black,
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {formLabel || label}
                                </Checkbox>
                            )}

                            {type === 'radio' && (
                                <RadioContainer
                                    radioStyles={radioStyles}
                                    options={options}
                                    value={value?.toString()}
                                    name={name}
                                    onChange={(val) => {
                                        if (val === 'true') {
                                            onChange(true)
                                            return
                                        }
                                        if (val === 'false') {
                                            onChange(false)
                                            return
                                        }
                                        onChange(val)
                                    }}
                                />
                            )}

                            {children}
                        </InputGroup>

                        {error && !type != inputTypes.hidden && (
                            <FormErrorMessage color="red.600">
                                {messages[error.message] || error.message}
                            </FormErrorMessage>
                        )}
                        {helpText}
                    </FormControl>
                )
            }}
        />
    )
}
FieldNewDesign.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    formLabel: PropTypes.any,
    type: PropTypes.oneOf([
        'text',
        'number',
        'password',
        'email',
        'phone',
        'tel',
        'select',
        'checkbox',
        'hidden',
        'checkboxSquare',
        'radio',
        'date'
    ]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
            width: PropTypes.string,
            height: PropTypes.string
        })
    ),
    rules: PropTypes.object,
    error: PropTypes.shape({message: PropTypes.string}),
    placeholder: PropTypes.string,
    inputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    control: PropTypes.object,
    defaultValue: PropTypes.any,
    helpText: PropTypes.any,
    children: PropTypes.any,
    messages: PropTypes.object,
    required: PropTypes.bool,
    checkboxStyles: PropTypes.object,
    radioStyles: PropTypes.object,
    labelProps: PropTypes.object,
    hasStarInLabel: PropTypes.bool,
    inputGroupProps: PropTypes.object
}

export default FieldNewDesign
